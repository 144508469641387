















import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  name: "SearchComponent",
  setup() {
    const isSearchOpen = ref(false);

    function toggleSearch() {
      const search = document.querySelectorAll(".search");

      if (!search) {
        return;
      }

      isSearchOpen.value = !isSearchOpen.value;
      search[0].classList.toggle("hidden");
    }

    return {
      toggleSearch,
      isSearchOpen,
    };
  },
});
